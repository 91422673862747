import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { H2 } from "../components/Heading"
import { LAYOUT } from "../constants"

interface ICounterImage {
  fluid: object
}

const Root = styled.div`
  padding: 40px 0 20px 0;
`

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const SummaryH2 = styled(H2)`
  margin-bottom: 15px;
`

const CounterImage = styled(Img)<ICounterImage>`
  max-width: 350px;

  width: 100%;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 564px;
  }
`

interface IHero {
  rest?: object
}

const RaisedSoFar: React.FC<IHero> = ({ ...rest }) => {
  const data = useStaticQuery(graphql`
    query RaisedSoFarQuery {
      counter: file(relativePath: { eq: "raisedSoFar/counter.png" }) {
        childImageSharp {
          fluid(maxWidth: 564) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <SummaryContainer>
        <SummaryH2 color={"GREY"} alignment={"CENTER"}>
          Raised so far
        </SummaryH2>
        <CounterImage
          loading={"eager"}
          fadeIn={false}
          fluid={data.counter.childImageSharp.fluid}
          alt={"£7 million"}
        />
      </SummaryContainer>
    </Root>
  )
}

export default RaisedSoFar
